<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- <el-form
        ref="listQuery"
        :rules="listQueryRules"
        :model="listQuery"
        label-width="130px"
      >
        <el-form-item label="请选择课表时间:" prop="value">
          <el-date-picker
            class="filter-item"
            v-model="listQuery.value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="changeDate"
          >
          </el-date-picker>
          <el-button
            class="filter-item"
            type="success"
            @click="save"
            :loading="btnLoading"
          >
            提交课表
          </el-button>
          <el-button class="filter-item" type="primary" @click="back">
            返回
          </el-button>
        </el-form-item>
      </el-form> -->
      <el-button
        class="filter-item"
        type="success"
        @click="save"
        :loading="btnLoading"
      >
        提交课表
      </el-button>
      <el-button class="filter-item" type="primary" @click="back">
        返回
      </el-button>
    </div>
    <!-- 顶部 -->
    <div class="table">
      <div class="table_item" v-for="item in tableTop" :key="item.id">
        {{ item.name }}
      </div>
    </div>
    <!-- 操作 -->
    <div class="table">
      <div
        class="table_item"
        style="border-bottom: 1px solid #dcdfe6"
        v-for="(item, key) in tableTop"
        :key="item.id"
      >
        <template v-if="key == 0">
          {{ item.operation }}
        </template>
        <!-- arrange = true -->
        <el-button
          type="primary"
          v-else
          @click="addweek(item.week_id, item.name)"
          >{{ item.operation }}</el-button
        >
      </div>
    </div>
    <!-- 早上 -->
    <div class="table">
      <div
        class="table_item"
        style="height: 200px"
        v-for="(item, key) in tableTop"
        :key="item.id"
      >
        <p v-if="key == 0">
          {{ item.morning }}
        </p>
        <div
          v-else
          v-for="it in item.morning"
          :key="it.id"
          class="calendar_tag"
        >
          <p>课节:{{ it.class_time_config[0].class_time_config_name }}</p>
          <p>上课教师:{{ it.teacher_name }}</p>
          <p>课程名称:{{ it.courseName }}</p>
          上课地点:{{ it.classroom }}
        </div>
      </div>
    </div>
    <div class="table">
      <div
        class="table_item"
        style="height: 200px"
        v-for="(item, key) in tableTop"
        :key="item.id"
      >
        <p v-if="key == 0">
          {{ item.afternoon }}
        </p>
        <div
          v-else
          v-for="it in item.afternoon"
          :key="it.id"
          class="calendar_tag"
        >
          <p>课节:{{ it.class_time_config[0].class_time_config_name }}</p>
          <p>上课教师:{{ it.teacher_name }}</p>
          <p>课程名称:{{ it.courseName }}</p>
          上课地点:{{ it.classroom }}
        </div>
      </div>
    </div>
    <div class="table">
      <div
        class="table_item"
        style="height: 200px"
        v-for="(item, key) in tableTop"
        :key="item.id"
      >
        <p v-if="key == 0">
          {{ item.night }}
        </p>
        <div v-else v-for="it in item.night" :key="it.id" class="calendar_tag">
          <p>课节:{{ it.class_time_config[0].class_time_config_name }}</p>
          <p>上课教师:{{ it.teacher_name }}</p>
          <p>课程名称:{{ it.courseName }}</p>
          上课地点:{{ it.classroom }}
        </div>
      </div>
    </div>

    <el-dialog
      :title="week_name + '课表安排'"
      :visible.sync="arrange"
      :close-on-click-modal="false"
    >
      <el-form
        ref="listQuery"
        :rules="listQueryRules"
        :model="listQuery"
        label-width="130px"
      >
        <el-form-item label="请先选择课程时间:" required>
          <div class="flex">
            <el-form-item prop="school_year">
              <el-date-picker
                v-model="listQuery.school_year"
                type="year"
                class="filter-item"
                :clearable="false"
                value-format="yyyy"
                placeholder="选择学年"
                @change="
                  selectDepartment(),
                    getCollegeList(listQuery.school_id),
                    (listQuery.college_id = null),
                    (colleges = []),
                    (listQuery.grade_id = null),
                    (grade = []),
                    (listQuery.class_id = null),
                    (classes = [])
                "
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="semester">
              <el-select
                v-model="listQuery.semester"
                placeholder="请选择学期"
                style="width: 150px; margin-left: 20px"
                class="filter-item"
                @change="selectDepartment()"
              >
                <el-option label="第一学期" :value="1" />
                <el-option label="第二学期" :value="2" />
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="请选择班级:" prop="class_id">
          <div class="flex">
            <el-form-item>
              <el-select
                v-model="listQuery.college_id"
                placeholder="请选择院系"
                style="width: 150px"
                class="filter-item"
                @change="
                  selectDepartment(),
                    getGradeList(),
                    (listQuery.grade_id = null),
                    (grade = []),
                    (listQuery.major_id = null),
                    (majorList = []),
                    (listQuery.class_id = null),
                    (classes = [])
                "
              >
                <el-option
                  v-for="(v, i) in colleges"
                  :key="i"
                  :label="v.name"
                  :value="v.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="listQuery.grade_id"
                placeholder="请选择年级"
                style="width: 150px; margin-left: 10px"
                class="filter-item"
                @change="
                  getmajorList(listQuery.college_id),
                    (listQuery.major_id = null),
                    (majorList = []),
                    (listQuery.class_id = null),
                    (classes = [])
                "
              >
                <el-option
                  v-for="item in grades"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="listQuery.major_id"
                placeholder="请选择专业"
                style="width: 150px; margin-left: 10px"
                class="filter-item"
                @change="
                  selectDepartment(),
                    getClassList(),
                    (listQuery.class_id = null),
                    (classes = [])
                "
              >
                <el-option
                  v-for="(v, i) in majorList"
                  :key="i"
                  :label="v.name"
                  :value="v.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="listQuery.class_id"
                placeholder="请选择班级"
                style="width: 150px; margin-left: 20px"
                class="filter-item"
              >
                <el-option
                  v-for="item in classes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="校区:" prop="campus_id">
          <el-select
            v-model="listQuery.campus_id"
            placeholder="请选择校区"
            style="width: 150px"
            class="filter-item"
            @change="selectDepartment()"
          >
            <el-option
              v-for="(item, index) in campuslist"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="请选择课表时间:" prop="value">
          <el-date-picker
            class="filter-item"
            v-model="listQuery.value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
            @change="changeDate"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-form
        ref="list"
        :rules="formDataRules"
        :model="{ list: list }"
        label-width="100px"
      >
        <div class="diaLogBox" v-for="(item, liKey) in list" :key="liKey">
          <el-form-item
            label="课程名称:"
            :prop="'list.' + liKey + '.school_course_id'"
            :rules="formDataRules.school_course_id"
          >
            <el-select
              v-model="item.school_type_id"
              placeholder="请选择课程类型"
              style="width: 150px"
              class="filter-item"
              @change="
                getCourseList(item.school_type_id, liKey),
                  (item.school_course_id = null),
                  (item.teacher_id = null)
              "
            >
              <el-option label="普通课程" :value="2" />
              <el-option label="电竞课程" :value="1" />
            </el-select>
            <el-select
              v-if="item.school_type_id"
              v-model="item.school_course_id"
              placeholder="请选择课程"
              style="width: 150px; margin-left: 10px"
              class="filter-item"
              @change="
                getTeacherList($event, item.school_type_id, liKey),
                  (item.teacher_id = null)
              "
            >
              <el-option
                v-for="item in item.courses"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="上课教师:"
            :prop="'list.' + liKey + '.teacher_id'"
            :rules="formDataRules.teacher_id"
          >
            <el-select
              v-model="item.teacher_id"
              placeholder="请选择上课教师"
              style="width: 150px"
              class="filter-item"
              @change="laoshi($event, liKey)"
            >
              <el-option
                v-for="item in item.teachers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间段:" required>
            <div
              v-for="(classtime, key) in item.class_time_config"
              :key="key"
              style="margin-bottom: 10px"
              class="flex"
            >
              <el-form-item
                :prop="
                  'list.' + liKey + '.class_time_config.' + key + '.timeQuantum'
                "
                :rules="formDataRules.timeQuantum"
              >
                <el-select
                  v-model="classtime.timeQuantum"
                  placeholder="请选择时间段"
                  style="width: 150px"
                  class="filter-item"
                  @change="
                    getClassTimeConfig(classtime.timeQuantum, liKey, key),
                      (classtime.class_time_config_id = null)
                  "
                >
                  <el-option label="上午" :value="1" />
                  <el-option label="下午" :value="2" />
                  <el-option label="晚上" :value="3" />
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="
                  'list.' +
                  liKey +
                  '.class_time_config.' +
                  key +
                  '.class_time_config_id'
                "
                :rules="formDataRules.timeQuantum"
              >
                <el-select
                  v-model="classtime.class_time_config_id"
                  placeholder="请选择某节课"
                  style="width: 150px; margin-left: 10px"
                  class="filter-item"
                  @change="kejie(liKey, key)"
                >
                  <el-option
                    v-for="item in item.configs[key]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>

              <el-button
                v-if="classtime.isadd"
                type="primary"
                @click="addTimeQuantum(liKey, key)"
                style="margin-left: 10px; height: 40px"
                >添加</el-button
              >
              <el-button
                v-else
                type="danger"
                @click="delTimeQuantum(liKey, key)"
                style="margin-left: 10px; height: 40px"
                >删除</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            label="课室:"
            :prop="'list.' + liKey + '.classroom'"
            :rules="formDataRules.classroom"
          >
            <el-input
              v-model="item.classroom"
              placeholder="请输入课室"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="课堂备注:"
            :prop="'list.' + liKey + '.class_requirements'"
            :rules="formDataRules.class_requirements"
          >
            <el-input
              v-model="item.class_requirements"
              type="textarea"
              style="width: 400px"
              :rows="4"
              placeholder="请输入课堂备注"
            ></el-input>
          </el-form-item>
          <div class="diaLogBox_btn">
            <!-- <el-button @click="shiftUp(liKey)">上移</el-button>
            <el-button
              @click="shiftDown(liKey)"
              style="margin-left: 0; margin-top: 10px"
              >下移</el-button
            > -->
          </div>
          <i class="el-icon-error" @click="remove(liKey)"></i>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
        <el-button type="success" @click="addList" :loading="btnLoading">
          添加
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      index_i: 0,
      week_name: "",
      timeQuantumTags: [],
      tableTop: [
        {
          name: "时间段",
          morning: "早上",
          afternoon: "下午",
          night: "晚上",
          operation: "操作",
        },
        {
          name: "星期一",
          week_id: 1,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
        {
          name: "星期二",
          week_id: 2,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
        {
          name: "星期三",
          week_id: 3,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
        {
          name: "星期四",
          week_id: 4,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
        {
          name: "星期五",
          week_id: 5,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
        {
          name: "星期六",
          week_id: 6,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
        {
          name: "星期日",
          week_id: 0,
          morning: [],
          afternoon: [],
          night: [],
          operation: "操作",
        },
      ],
      tableData: [
        {
          date: "早上",
        },
        {
          date: "下午",
        },
        {
          date: "晚上",
        },
        {
          date: "操作",
        },
      ],
      configs: [],
      departments: [], // 院系
      grades: [], // 年级
      sclass_s: [], //班级
      value1: null,
      arrange: false,
      btnLoading: false,
      colleges: [],
      classes: [],
      courses: [],
      teacherList: [],
      teachers: [],
      week_index: 0,
      form: {
        start_at: null, //开始时间
        end_at: null, //结束时间
        class_id: null, //班级id
        semester: null, //学期
        schedules: [
          // {
          //   //星期
          //   week_id: null,
          //   list: [
          //     {
          //       school_type_id: null,
          //       class_time_config_id: null, //课节 差
          //       school_course_id: null, //课程id 差
          //       teacher_id: null, //教师id 差
          //       timeQuantum: null, //时间段 差
          //       classroom: null, //课室 差
          //       class_requirements: null, //课堂备注 差
          //     },
          //   ],
          // },
        ],
      },
      list: [],
      majorList: [],
      campuslist: [], //校区
      listQuery: {
        school_id: null,
        school_year: null,
        semester: null,
        college_id: null,
        grade_id: null,
        class_id: null,
        start_time: null,
        end_time: null,
        campus_id: null,
        value: [],
      },
      listQueryRules: {
        school_id: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        school_year: [
          { required: true, message: "请选择学年", trigger: "change" },
        ],
        campus_id: [
          { required: true, message: "请选择校区", trigger: "change" },
        ],
        semester: [
          { required: true, message: "请选择学期", trigger: "change" },
        ],
        college_id: [
          { required: true, message: "请选择院系", trigger: "change" },
        ],
        grade_id: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        major_id: [
          { required: true, message: "请选择专业", trigger: "change" },
        ],
        class_id: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        value: [
          {
            type: "array",
            required: true,
            message: "请选择课表时间",
            trigger: "change",
          },
        ],
      },
      formDataRules: {
        class_time_config: [
          {
            type: "array",
            required: true,
            message: "请选择某节课",
            trigger: "change",
          },
        ],
        school_course_id: [
          { required: true, message: "请选择课程", trigger: "change" },
        ],
        timeQuantum: [
          { required: true, message: "请选择时间段", trigger: "change" },
        ],
        class_time_config_id: [
          { required: true, message: "请选择课节", trigger: "change" },
        ],
        teacher_id: [
          { required: true, message: "请选择上课的教师", trigger: "change" },
        ],
        classroom: [
          { required: true, message: "请输入课室", trigger: "change" },
        ],
        class_requirements: [
          { required: true, message: "请输入课堂备注", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getCollegeList();
    this.getcampusList();
  },
  methods: {
    // 获取校区列表
    getcampusList() {
      request({
        url: "/api/schoolend/school/campusList",
        method: "get",
        params: {
          is_setting: 1,
          is_on: 1,
        },
      }).then((response) => {
        this.campuslist = response.data.data;
      });
    },
    //清理list数据
    selectDepartment() {
      this.list = [
        {
          school_type_id: null,
          class_time_config_ids: [],
          configs: {},
          class_time_config: [
            {
              timeQuantum: null,
              isadd: true,
              class_time_config_id: null, //课节
              class_time_config_name: null, //课节
            },
          ],
          school_course_id: null, //课程id
          teacher_id: null, //教师id
          teacher_name: null, //教师id
          timeQuantum: null, //时间段
          classroom: null, //课时
          class_requirements: null, //课堂备注
          // courseType: null, //课程类别名称
          courseName: null, //课程名称
        },
      ];
    },
    cancel() {
      this.arrange = false;
    },
    laoshi(e, likey) {
      this.list[likey].teachers.forEach((item) => {
        if (item.id == e) {
          this.list[likey].teacher_name = item.name;
        }
      });
    },
    kejie(likey, key) {
      this.list[likey].configs[key].forEach((item) => {
        if (
          item.id ==
          this.list[likey].class_time_config[key].class_time_config_id
        ) {
          this.list[likey].class_time_config[key].class_time_config_name =
            item.name;
        }
      });
    },
    closeTags(e) {
      console.log(e);
      this.timeQuantumTags.splice(e, 1);
    },
    delTimeQuantum(list, key) {
      // this.list.forEach((item,index) => {
      //   console.log(item, index);
      // });
      this.list[list].class_time_config.splice(key, 1);
    },
    addTimeQuantum(list, key) {
      this.list[list].class_time_config[key].isadd = false;
      this.list[list].class_time_config.push({
        timeQuantum: null,
        isadd: true,
        class_time_config_id: null, //课节
        class_time_config_name: null, //课节名
      });
    },
    changeYear(e) {
      this.listQuery = {
        school_year: e,
        semester: null,
        department: null,
        grade: null,
        start_time: null,
        end_time: null,
        class_id: null,
      };
      this.form.class_id = "";
      this.form.semester = "";
    },
    changeDate(e) {
      if (e) {
        this.form.start_at = e[0];
        this.form.end_at = e[1];
        this.listQuery.start_at = e[0];
        this.listQuery.end_at = e[1];
      } else {
        this.form.start_at = "";
        this.form.end_at = "";
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
    saveData() {
      this.$refs["list"].validate((valid) => {
        this.$refs["listQuery"].validate((valid) => {
          if (valid) {
          } else {
            return;
          }
        });

        if (valid) {
          console.log(this.list);
          let isWeekId = this.form.schedules.findIndex(
            (item) => item.week_id == this.week_index
          );
          this.form.schedules[isWeekId].list = this.list;
          this.form.schedules.forEach((item) => {
            let listIndex = this.tableTop.findIndex(
              (index) => index.week_id == item.week_id
            );
            let morningList = [];
            let afternoonList = [];
            let nightList = [];
            if (item.week_id == this.week_index) {
              item.list.forEach((listItem, i) => {
                let class_time_config_ids = [];
                listItem.class_time_config.forEach((timelist) => {
                  var data = JSON.parse(JSON.stringify(listItem));
                  class_time_config_ids.push(timelist.class_time_config_id);
                  listItem.class_time_config_ids = class_time_config_ids;
                  //判断上中晚时间段
                  if (timelist.timeQuantum == 1) {
                    listItem.timeQuantum = 1;
                    data.class_time_config = [timelist];
                    listItem = data;
                    morningList.push(listItem);
                  } else if (timelist.timeQuantum == 2) {
                    listItem.timeQuantum = 2;
                    data.class_time_config = [timelist];
                    listItem = data;
                    afternoonList.push(listItem);
                  } else if (timelist.timeQuantum == 3) {
                    listItem.timeQuantum = 3;
                    data.class_time_config = [timelist];
                    listItem = data;
                    nightList.push(listItem);
                  }
                });
                //删除属性
                // delete listItem.class_time_config;
              });
              this.tableTop[listIndex].morning = morningList;
              this.tableTop[listIndex].afternoon = afternoonList;
              this.tableTop[listIndex].night = nightList;
            }
          });
          this.arrange = false;
          console.log(this.form, "===form");
        }
      });
    },
    addweek(index, name) {
      console.log(this.form);
      this.week_index = index;
      this.week_name = name;
      this.list = [];
      let isWeekId = this.form.schedules.findIndex(
        (item) => item.week_id == index
      );
      console.log(isWeekId);
      if (isWeekId == -1) {
        this.form.schedules.push({
          //星期
          week_id: index,
          list: [],
        });
        this.list.push({
          school_type_id: null,
          class_time_config_ids: [],
          configs: {},
          class_time_config: [
            {
              timeQuantum: null,
              isadd: true,
              class_time_config_id: null, //课节
              class_time_config_name: null, //课节
            },
          ],
          school_course_id: null, //课程id
          teacher_id: null, //教师id
          teacher_name: null, //教师id
          timeQuantum: null, //时间段
          classroom: null, //课时
          class_requirements: null, //课堂备注
          // courseType: null, //课程类别名称
          courseName: null, //课程名称
        });
      } else {
        if (this.form.schedules[isWeekId].list.length) {
          this.list = this.form.schedules[isWeekId].list;
        } else {
          this.list.push({
            school_type_id: null,
            class_time_config_ids: [],
            configs: {},
            class_time_config: [
              {
                timeQuantum: null,
                isadd: true,
                class_time_config_id: null, //课节 差
                class_time_config_name: null, //课节 差
              },
            ],
            school_course_id: null, //课程id 差
            teacher_id: null, //教师id 差
            teacher_name: null, //教师id 差
            timeQuantum: null, //时间段 差
            classroom: null, //课时 差
            class_requirements: null, //课堂备注 差
            courseType: null, //课程类别名称
            courseName: null, //课程名称
          });
        }
      }
      this.arrange = true;
    },
    getClassTimeConfig(v, likey, key) {
      request({
        url: "/api/schoolend/school/campusClassTimeConfig",
        method: "get",
        params: {
          limit: 999999999999999,
          type: v,
          campus_id: this.listQuery.campus_id,
        },
      }).then((response) => {
        this.list[likey].configs[key] = response.data;
        this.$forceUpdate(); //强制更新
      });
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
          is_setting: 1,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getmajorList() {
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: {
          college_id: this.listQuery.college_id,
          grade_id: this.listQuery.grade_id,
          limit: 999999999,
        },
      }).then((response) => {
        this.majorList = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 999999999999999,
          school_year: this.listQuery.school_year,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getClassList() {
      this.classes = [];
      this.listQuery.class_id = null;
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: {
          limit: 9999999999999,
          grade_id: this.listQuery.grade_id,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.classes = response.data.data;
      });
    },
    getTeacherList(e, v, likey) {
      let index = this.list[likey].courses.find((item) => item.id == e);
      this.list[likey].courseName = index.name;
      // return
      var url = null;
      let data = {};
      if (v == 1) {
        url = "/api/schoolend/teacher/eSportslist";
        data = {
          limit: 99999999999999,
          course_id: e,
          school_course_id: e,
          semester: this.listQuery.semester,
          school_year: this.listQuery.school_year,
          not_status: 3,
        };
      } else {
        url = "/api/schoolend/teacher/list";
        data = {
          limit: 99999999999999,
          course_id: e,
          school_course_id: e,
          not_status: 3,
        };
      }
      request({
        url: url,
        method: "get",
        params: data,
      }).then((response) => {
        this.list[likey].teachers = response.data.data;
        // if (this.list.length == this.teachers.length) {
        //   this.teachers[likey] = response.data.data;
        // } else {
        //   this.teachers.push(response.data.data);
        // }
        this.$forceUpdate(); //强制更新
      });
    },
    getCourseList(v, key) {
      var url = null;
      this.list.school_course_id = "";
      this.teachers[key] = [];
      let data = {};
      if (v == 1) {
        url = "/api/schoolend/course/eSportslist";
        data = {
          limit: 99999999999999,
          school_year: this.listQuery.school_year,
          semester: this.listQuery.semester,
          college_id: 0,
        };
      } else {
        url = "/api/schoolend/course/list";
        data = {
          limit: 99999999999999,
          college_id: this.listQuery.college_id,
        };
      }
      request({
        url: url,
        method: "get",
        params: data,
      }).then((response) => {
        this.list[key].courses = response.data.data;
        this.$forceUpdate(); //强制更新
        // if (this.list.length == this.courses.length) {
        //   this.courses[key] = response.data.data;
        //   this.$forceUpdate(); //强制更新
        // } else {
        //   this.courses.push(response.data.data);
        //   this.$forceUpdate(); //强制更新
        // }
      });
    },
    //上移下移
    shiftUp(key) {
      if (key == 0) {
        this.$message({
          message: "已是第一条不能上移",
          type: "warning",
        });
        return;
      }
      this.list.splice(key - 1, 0, this.list[key]);
      this.list.splice(key + 1, 1);
      this.teachers.splice(key - 1, 0, this.teachers[key]);
      this.teachers.splice(key + 1, 1);
      this.courses.splice(key - 1, 0, this.courses[key]);
      this.courses.splice(key + 1, 1);
      this.configs.splice(key - 1, 0, this.configs[key]);
      this.configs.splice(key + 1, 1);
    },
    shiftDown(key) {
      if (key === this.list.length - 1) {
        this.$message({
          message: "已是最后一条不能下移",
          type: "warning",
        });
        return;
      }
      this.list.splice(key + 2, 0, this.list[key]);
      this.list.splice(key, 1);
      this.courses.splice(key + 2, 0, this.courses[key]);
      this.courses.splice(key, 1);
      this.teachers.splice(key + 2, 0, this.teachers[key]);
      this.teachers.splice(key, 1);
      this.configs.splice(key + 2, 0, this.configs[key]);
      this.configs.splice(key, 1);
      // this.form.schedules.forEach((item, index) => {
      //   if (item.week_id == this.week_index) {
      //     if (key === this.form.schedules[index].list.length - 1) {
      //       this.$message({
      //         message: "已是最后一条不能下移",
      //         type: "warning",
      //       });
      //       return;
      //     }
      //     //在上一项插入该项
      //     this.form.schedules[index].list.splice(
      //       key + 2,
      //       0,
      //       this.form.schedules[index].list[key]
      //     );
      //     //删除后一项
      //     this.form.schedules[index].list.splice(key, 1);
      //   }
      // });
    },
    remove(liKey) {
      // let index = this.form.schedules.findIndex(
      //   (item) => item.week_id == this.week_index
      // );
      // console.log(this.form.schedules, "=====================schedules");
      // console.log(index, "=====================index");
      // console.log(key, "=====================key");
      // console.log(liKey, "=====================liKey");
      // this.form.schedules.forEach((item, index) => {
      //   if (item.week_id == this.week_index) {
      //     if (this.form.schedules[index].list.length -1 == 0) {
      //       return
      //     }
      // this.form.schedules[index].list.splice(liKey, 1);
      //   }
      // });
      this.list.splice(liKey, 1);
    },
    addList() {
      this.list.push({
        school_type_id: null,
        class_time_config_ids: [],
        configs: {},
        class_time_config: [
          {
            timeQuantum: null,
            isadd: true,
            class_time_config_id: null, //课节 差
            class_time_config_name: null, //课节 差
          },
        ],
        school_course_id: null, //课程id 差
        teacher_id: null, //教师id 差
        teacher_name: null, //教师id 差
        timeQuantum: null, //时间段 差
        classroom: null, //课时 差
        class_requirements: null, //课堂备注 差
      });
    },
    // 查找
    handlerCheck() {},
    back() {
      this.$router.go(-1);
    },
    save() {
      this.$refs["listQuery"].validate((valid) => {
        if (valid) {
          this.form.semester = this.listQuery.semester;
          this.form.class_id = this.listQuery.class_id;
          var data = JSON.parse(JSON.stringify(this.form));
          let newList = [];
          data.schedules.forEach((liItem, index) => {
            if (liItem.list.length > 0) {
              liItem.list.forEach((item) => {
                delete item.teacher_name;
                delete item.courses;
                delete item.teachers;
                delete item.configs;
                delete item.class_time_config;
              });
              newList.push(liItem);
            } else {
              delete data.schedules[index];
            }
            this.$forceUpdate(); //强制更新
          });
          data.schedules = newList;
          if (!data.schedules.length > 0) {
            this.$message.error("课程表不能为空!!");
            return;
          }
          this.btnLoading = true;
          request({
            url: "/api/schoolend/schedule/manyStore",
            method: "post",
            data: data,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1); //返回上一层
            })
            .catch((err) => {
              this.btnLoading = false;
              //判断是否有相同课程
              if (err.code == -2) {
                this.$confirm("同一天存在多节冲突的课程, 是否继续?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.form.is_confirm = 1;
                    request({
                      url: "/api/schoolend/schedule/manyStore",
                      method: "post",
                      data: this.form,
                    }).then((response) => {
                      this.btnLoading = false;
                      this.dialogFormVisible = false;
                      this.$message({
                        type: "success",
                        message: "操作成功",
                      });
                      this.$router.go(-1);
                    });
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消",
                    });
                  });
              }
            });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.class_box {
  padding: 10px;
}
.el-form-item {
  margin-bottom: 20px !important;
}
.table {
  display: flex;
  width: 100%;
  .table_item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
    border: 1px solid #dcdfe6;
    overflow-y: scroll;
    border-left: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &:first-child {
      border-left: 1px solid #dcdfe6;
    }
    .calendar_tag {
      color: #1890ff;
      background-color: #e8f4ff;
      border-color: #d1e9ff;
      border-radius: 4px;
      text-align: left;
      padding: 10px;
      margin: 10px;
      font-size: 14px;
      p {
        margin: 5px 0;
      }
    }
  }
}
.diaLogBox {
  position: relative;
  padding: 10px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;

  .diaLogBox_btn {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 90px;
    bottom: 36px;
  }
  .el-icon-error {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
  }
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
</style>
